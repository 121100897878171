<template>
    <div class="modal" v-if="modelValue" :class="{ active : modelValue }">
        <div class="modal_container">
            <div class="modal_header border">
                <div class="modal_info">
                    <h1>Send Portal Message</h1>
                </div>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <div class="setting_wpr">
                    <Form @submit="handleSendMessage">
                        <portal-message :contacts="contacts.join(',')" :success-handler="() => closeModal()" ref="portal-message-component" />
                        <div class="action_wpr mt-5">
                            <button :disabled="loader" type="button" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                            <button :disabled="loader" class="btn save_btn" type="button" @click="handleSendMessage"><i class="fa fa-spinner fa-spin" v-if="loader"></i> {{ loader ? ' Sending' : 'Send' }}</button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { Form } from 'vee-validate'
    import { mapState } from 'vuex'

    const PortalMessage = defineAsyncComponent(() => import('@/pages/contact/components/PortalMessage'))

    export default {
        name: 'Contact Portal Message',

        data () {
            return {

            }
        },

        props: {
            modelValue: Boolean,
            contacts: Array,
        },

        emits: ['update:modelValue'],

        components: {
            Form,
            PortalMessage
        },

        watch: {
            modelValue (value) {
                const vm = this;
                if (value == true) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }

            },
        },

        computed: {
            ...mapState({
                loader: state => state.contactModule.sendMessageLoader,
            }),
        },

        methods: {
            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            handleSendMessage () {
                const vm = this;

                const portalMessage = vm.$refs['portal-message-component'];

                portalMessage.handleSendMessage();
            }
        },
    }
</script>
